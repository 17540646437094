<template>
  <b-navbar
    id="home-nav"
    class="pt-3 transition-2"
    toggleable="md"
    type="light"
    fixed="top"
  >
    <div class="container">
      <b-navbar-brand href="#">
        <router-link :to="{ name: 'anonymousRoute-home' }">
          <img
            src="/img/digital-staging-logo.svg"
            alt="Digital Staging"
            style="height: 40px"
          />
        </router-link>
      </b-navbar-brand>
      <b-navbar-toggle target="home-nav-collapse"></b-navbar-toggle>
      <b-collapse id="home-nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <li class="nav-item font-weight-bold">
            <span class="nav-link" @click="goto('our-characteristics')">
              3つの特徴
            </span>
          </li>

          <li class="nav-item font-weight-bold">
            <span class="nav-link" @click="goto('how-it-works')">
              使い方
            </span>
          </li>
          <li class="nav-item font-weight-bold">
            <router-link
              class="nav-link"
              :to="{ name: 'anonymousRoute-inquiry' }"
            >
              お問い合わせ
            </router-link>
          </li>
          <li class="nav-item font-weight-bold">
            <router-link
              class="nav-link"
              :to="
                currentUser.user_role.role_id === 4
                  ? { name: 'userRoute-dashboard' }
                  : { name: 'adminRoute-dashboard' }
              "
              v-if="isLoggedIn"
            >
              ダッシュボード
            </router-link>
            <router-link
              class="nav-link"
              :to="{ name: 'anonymousRoute-login' }"
              v-else
            >
              サインイン
            </router-link>
          </li>
        </b-navbar-nav>
      </b-collapse>
    </div>
  </b-navbar>
</template>

<script>
// Import Vuex/Store
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['isLoggedIn', 'currentUser']),
  },

  mounted() {
    this.setShadowOnScroll();
  },

  methods: {
    goto(refName) {
      var element = this.$parent.$refs[refName];
      element.scrollIntoView({ behavior: 'smooth' });
    },

    setShadowOnScroll() {
      window.onscroll = () => {
        let homeNav = document.querySelector('#home-nav');
        let scrollValue = document.documentElement.scrollTop;

        if (homeNav !== null) {
          if (scrollValue > 20) {
            homeNav.classList.add('shadow-1');
          } else {
            homeNav.classList.remove('shadow-1');
          }
        }
      };
    },
  },
};
</script>

<style scoped>
#home-nav {
  background: #fff;
}

@media only screen and (max-width: 991px) {
  #home-nav {
    max-width: 100%;
  }

  #home-nav .container {
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  #home-nav {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }
}

.nav-link:nth-child(1),
.nav-link:nth-child(2),
.nav-link:nth-child(3) {
  margin-right: 20px;
}

@media only screen and (max-width: 835px) {
  .nav-link:nth-child(1),
  .nav-link:nth-child(2),
  .nav-link:nth-child(3) {
    margin-right: 10px;
  }
}
.nav-link {
  cursor: pointer;
}
</style>
