<template>
  <div>
    <home-navbar></home-navbar>
    <div class="banner-wrapper">
      <img src="/img/default-banner.png" alt="Sign up Banner" />
    </div>
    <div class="section-wrapper">
      <div class="container p-0">
        <div class="content-wrapper">
          <div class="row">
            <div class="col-12">
              <div class="content">
                <h1 class="page-title w-100 fs-1 text-center title">ご利用規約</h1>
                <p>
                  「デジタルステージング」（以下、本サー
                  ビス）は、株式会社RealtyBank（以下、当社）が運営・管理するサー
                  ビスです。本規約は当社の規定について定めたもので、本サー
                  ビスをご利用されるすべての方（以下、ご利用者様）に適用されます。ご利用者様は、本規約に定める規定を承認のうえ、利用されたものとみなします。
                </p>
                <p>
                  第1条（定義）本規約において使用する用語の定義は、次の通りとします。
                  「本サー
                  ビス」とは、デジタルステージングが利用者様に提供するコンテンツ並びにサー
                  ビス、商品全般
                  「本規約」とは、すべてのこ利用者様に適用され、依頼（発注）時にお守りいただく規約
                  「利用者様」
                  とは、本サービスの問合せ、相談、申込、発注、成果物の受領などを含む一切の利用をされる法人又は個人
                  「ご利用者様情報」とは、
                  ご利用者様が当社に開示した属性に関する情報および取引に関する履歴等の情報
                  「写真」とは、弊社が制作納入した成果物
                </p>
                <p>
                  第2条（業務の範囲及び内容）当社は、こ利用者様より業務の依頼を受諾します。依頼業務の概要は、以下のリンクにあるデジタルステージング制作業務その他の付随する業務とします。具体的な個別契約内容及び範囲は、別途仕様書の定めにより利用者様との合意（書面又はメールによる合意）に至ったものとし、その他の口頭による合意、又は、利用規約•利用規約に反する合
                  意については受け付けないものとします。
                </p>
                <p>
                  第3条（利用手続と承認）本サー
                  ビスの利用を希望するこ利用者様は、本規約の全条項を理解し、同意した上、当社所定の申込方法により申込み、当社が所定の審査・承認したこ利用者様に対し、当社は本サー
                  ビスを提供するものとします。
                </p>
                <p>
                  第4条（利用開始日と納入日について）利用開始日は、決済が完了し注文が確定した日といたします。納品日についてはウェブ上に写真などの成果物の提供した日付、または指定のメールアドレスに発信した日を納入日とします。写真の変更・返品等は規定回数以上は受け入れません。
                </p>
                <p>
                  第5条（ご利用料金とお支払いについて）本サービスのこ利用料金は、本サイトに掲載される料金あるいは、別途見積書に提示したものになります。利用者様は、クラウドサ
                  ービス経由で発行された請求書に記載された支払い方法と期日に従うものとします。
                </p>
                <p>第6条（キャンセルとその他の解除について）</p>
                <p>
                  1.本サービスの写真及び付帯サービスの費用及び報酬は、成果物の特性上、お支払い以降のキャンセルについては返金に応じることはできかねます。
                </p>
                <p>
                  2.ご利用者様が次の各号のいずれかに該当した場合は、
                  当社は何らの催告を要することなく直ちにこ利用者様に対するサービスの提供を終了し、又は本サービスの全部又はー部を解除することができます。この場合、当社の利用者様に対する損害賠償の請求を妨げないものとします。
                </p>
                <ul>
                  <li>① 本規約に違反する行為をしたとき</li>
                  <li>
                    ② 営業停止又は営業の免許、 許可等の取消処分を受けたとき
                  </li>
                  <li>
                    ③ 支払い停止若しくは支払い不能の状態に陥ったとき、
                    又は手形若しくは小切手が不渡りになったとき
                  </li>
                  <li>
                    ④ 破産手続き開始、 民事再生手続き開始、 会社更生手続き開始、
                    特別清算開始の申し立てを受け、 又は自ら申立てを行ったとき
                  </li>
                  <li>⑤ 解散したとき</li>
                  <li>
                    ⑥資産又は信用状態に重大な変化が生じ、
                    本規約及び本サービスの利用契約に基づく債務の履行が困難になる恐れがあると認められるとき
                  </li>
                </ul>
                <p>
                  3.前項に規定する場合、
                  こ利用者様が当社に対して負担する一切の債務についてはその期限の利益を喪失するものとします。
                </p>
                <p>
                  第７条（返品及び追加修正オプション）1.弊社が明確に異なる成果物を提供したことが明らかになった場合、
                  弊社の成果物送信後1週間にこ連絡いただいた場合に限り、
                  例外として対応いたします。
                </p>
                <p>
                  第８条（商標について）本ウェブサイトで使用されている商標・ロゴマーク・商号は、
                  当社の商標です。
                  商標法またはその他の法律により認められている場合を除き、当社の事前の書面による承諾なしに、
                  これらを使用等することはできません。
                </p>
                <p>
                  第９条（機密保持義務） ご利用者様は、
                  本サービスのご利用を通じて知り得た当社および当社の関係者、
                  取引先企業その他の第三者の機密情報および個人情報を秘密として保持するものとし、
                  当社またはその他の情報提供者の事前の承諾なしに、
                  当該機密情報または個人情報の開示、
                  提供その他の権限のない行為をしてはならないものとします。
                </p>
                <p>
                  第1０条（個人情報の取扱いについて）ご利用者様の個人及び企業、
                  物件情報は、
                  不正アクセス・紛失，改ざん・漏洩などのリスクに対して適切な防止策を講じ、
                  厳正な管理により利用・保管いたします。
                  弊社スタッフは個人情報保護に関する重要性を認識し、
                  誓約書に署名をして個人情報管理の責任を負います。 なお、
                  ご利用者様へのサー ビス提供に必要な範囲に限り、
                  弊社スタッフ間で個人情報を共有いたします。 こ利用者様から、
                  個人情報の開示・訂正においてこ要望があった場合は、
                  こ本人であることを確認した上で、 速やかに対応いたします。
                  当社は、 当社が保有するこ利用者様の個人情報を、
                  当社が定める個人情報保護方針に従って管理します。
                </p>
                <p>
                  第1１条（反社会的勢力の排除）ご利用者様は、 過去、
                  現在および将来にわたり、 反社会的勢力（暴力団、 暴力団員、
                  暴力団員でなくなった時から5年を経過しない者、暴力団準構成員、
                  暴力団関係企業、 総会屋等、
                  社会運動等標ぼうゴロまたは特殊知能暴力集団等、
                  その他これらに準ずる者をいいます）に該当しないことを保証し、
                  および暴力的行為、 詐術，脅迫行為、 業務妨害行為等、
                  法令に抵触する行為またはそのおそれのある行為を行わないものとします。<br />
                  2.ご利用者様が前項の規約に違反した場合には、
                  当社は事前に通告することなくご利用者様の本サービス利用を停止し、または登録を削除する等の措置を講じることができるものとします。
                  これによりこ利用者様に何らの不利益または損害が生じたとしても、当社は一切の責任を負わないものとします。
                </p>
                <p>
                  第1２条（当社及びご利用者様の損害賠償責任）1.当社は、
                  故意または重大な過失がある場合を除き、
                  本サービスの利用に起因又は関連して契約者が被った損害を賠償する責任を負いません。<br />
                  2.ご利用者様は、その責めに帰すべき事由により本サー
                  ビスの利用に起因又は関連して当社又は他のこ利用者様その他の第三者に損害を与えたときは、その損害を賠償するものとします。
                </p>
                <p>
                  第1３条（譲渡禁止協議事項）ご利用者様は、本規約及び本サー
                  ビスの利用契約に基づく権利義務を第三者に譲渡することはできません。
                </p>
                <p>
                  第1４条（合意管轄裁判所）本規約に関する紛争は、東京地方裁判所を専属的合意管轄裁判所といたします。
                </p>
                <p>
                  第1５条（本規約の変更）当社は、本規約を変更することができます。本規約を変更する場合、当社は、当社のウェブサイトにて本規約を変更する旨及び変更後の本規約の内容並びにその効力発生時期を告知します。
                </p>
                <p>
                  第1６条（本サー ビスの終了）当社は、本サー
                  ビスの提供を終了することがあります。この場合、その場合は、その1か月前までにこ利用者様にその旨及び終了日を通知するものとします。終了についての損害については一切の資任を負いかねます。
                </p>
                <p>
                  第1７条（通知）当社からご利用者様への通知は、こ利用者様が本サー
                  ビスの申込み時に当社に届け出た電子メ
                  ールアドレスその他の連絡先に宛てて発し、その通知が通常到達すべきであった時に到達したものとみなします。
                </p>
                <p>
                  第1８条（準拠法）本規約は、日本法に基づき解釈されるものとします
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <home-footer></home-footer>
  </div>
</template>
<script>
// Import Custom Components
import homeNavbar from '../components/customer/Home-Navbar.vue';
import homeFooter from '../components/customer/Home-Footer.vue';

export default {
  metaInfo() {
    return {
      title: 'Digital Staging - Terms and Conditions',
    };
  },

  components: {
    'home-navbar': homeNavbar,
    'home-footer': homeFooter,
  },
};
</script>

<style scoped>
header.second-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #eaecf4;
  height: 100px;
  position: relative;
  top: 0;
  background-color: #fff;
  z-index: 100;
  left: 0;
  z-index: 9;
  padding: 0 40px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-box-shadow: 3px 3px 20px #303030 12;
  -moz-box-shadow: 3px 3px 20px #303030 12;
  box-shadow: 3px 3px 20px #303030 12;
}

@media (max-width: 990px) {
  header.second-header {
    background-color: transparent !important;
    z-index: 9 !important;
    padding-left: 0;
    height: auto;
    position: relative;
    padding: 8px 18px;
  }
}

header.second-header .navbar-brand img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media (max-width: 991px) {
  header.second-header .navbar-brand img {
    height: 28px;
  }
}

.banner-wrapper img {
  width: 100%;
  height: 354px;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}

@media (max-width: 991px) {
  .banner-wrapper img {
    height: 160px;
  }
}

.section-wrapper .content-wrapper {
  padding: 120px 0 120px 0;
}

.section-wrapper .content-wrapper .content {
  margin-right: 26px;
}

.section-wrapper .content-wrapper .title {
  font-weight: 500;
}

.section-wrapper .content-wrapper p {
  color: #232222;
  font-size: 18px;
}

.section-wrapper .content-wrapper ul li {
  list-style: none;
}

@media (max-width: 576px) {
  .section-wrapper .content-wrapper {
    padding: 0;
    height: 60vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}

@media (max-width: 991px) {
  .section-wrapper {
    padding: 26px 0 120px 26px;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
    position: relative;
    top: -35px;
    background: #fff;
  }

  .section-wrapper .content-wrapper {
    padding: 0;
  }

  .section-wrapper .content-wrapper .title {
    text-align: left !important;
    margin: 0;
    font-size: 22px !important;
  }

  .section-wrapper .content-wrapper p,
  .section-wrapper .content-wrapper ul li {
    font-size: 14px;
  }
  
}
</style>
